<template>
  <div
    class="ui-w-full"
    :class="{ disabled }"
  >
    <div
      :class="[inputState.border, size, disabledStyle]"
      class="ui-flex c-bg-white ui-transition-all ui-in-out ui-duration-300 ui-justify-between ui-p-4 ui-rounded ui-h-input ui-overflow-hidden ui-antialiased"
      @click="focus"
    >
      <div class="ui-flex-grow ui-items-center ui-w-full ui-select-none">
        <div
          :class="inputState.label"
          class="ui-transition-all ui-in-out ui-font-medium ui-duration-300 ui-whitespace-no-wrap ui-heading-dsk-label ui-transform ui-w-full ui-truncate"
        >
          {{ label }}
        </div>

        <c-datepicker
          :value="value"
          :name="name"
          v-if="isDate"
          v-on="$listeners"
          @focus="handleFocus"
          @blur="handleBlur"
          @animationstart="handleAnimation"
        />
        <imask
          :type="type"
          :class="[disabledStyle]"
          :value="value"
          :mask="maskConfig"
          :unmask="!masked"
          :maxlength="maxlength"
          :disabled="disabled"
          :name="name"
          ref="input"
          v-else
          class="ui-w-full focus:ui-outline-none ui-text-input-value ui-font-medium ui-text-input ui-transform ui-translate-y-input ui-block c-bg-transparent"
          v-on="$listeners"
          @focus="handleFocus"
          @blur="handleBlur"
          @animationstart="handleAnimation"
        />
      </div>

      <div
        v-if="loading"
        class="ui-flex ui-justify-center ui-items-center"
      >
        <icon
          name="loading"
          outline
          color="#ccc"
          class="ui-spinner ui-animation-spin ui-animation-3s ui-animation-linear ui-animation-infinite"
          width="26"
          height="26"
        />
      </div>
      <div
        v-else
        class="ui-flex ui-justify-center ui-items-center"
      >
        <slot
          :icon="{ iconStyle: inputState.color }"
          name="icon"
        />
        <icon
          v-if="errorIcon"
          :color="inputState.color"
          data-testid="error-icon"
          name="warning"
        />
        <icon
          v-if="successIcon"
          :color="inputState.color"
          data-testid="success-icon"
          name="check"
        />
      </div>
    </div>
    <div
      v-if="info && info.message"
      :style="{ color: inputState.infoColor }"
      class="ui-flex ui-flex-row ui-items-center ui-mt-1 ui-h-7 ui-select-none"
      data-testid="info"
    >
      <span>
        <icon
          v-if="info.icon && info.message"
          :color="inputState.infoColor"
          :filled="info.iconFilled"
          :name="info.icon"
          class="ui-w-3 ui-mr-1"
          data-testid="info-icon"
        />
      </span>
      <span
        :style="{ color: inputState.infoColor }"
        class="c-text-b3 c-text-gray"
        data-testid="info-message"
      >
        {{ info.message }}
      </span>
    </div>
  </div>
</template>

<script>
import CDatepicker from '@/components/c-datepicker';
import { Icon } from '@estrategiahq/coruja-web-ui';
import { IMaskComponent } from 'vue-imask';

const ACCEPT_ALL_REGEX = /^.*$/;

export default {
  name: 'CInput',
  components: {
    Icon,
    imask: IMaskComponent,
    CDatepicker,
  },
  props: {
    name: { type: String, default: '' },
    value: { type: String, default: '' },
    label: { type: String, required: true },
    type: { type: String, default: 'text' },
    checkRegexp: { type: String, default: null },
    maxlength: { type: Number, default: 255 },
    size: { type: String, default: 'ui-w-full' },
    error: { type: Boolean, default: false },
    info: { type: Object, default: null },
    success: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    mask: { type: [String, Array, RegExp], default: null },
    state: { type: String, default: 'DEFAULT' },
    masked: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    isAutofilled: false,
    isFocused: false,
    currentState: 'DEFAULT',
  }),
  computed: {
    disabledStyle() {
      return this.disabled ? 'ui-cursor-not-allowed ui-select-none ui-opacity-50' : '';
    },
    inputState() {
      const isActive = this.value || this.isFocused || this.isAutofilled;
      const labelSize = isActive ? 'ui-translate-y-input-content ui-text-label ui-font-bold' : 'ui-text-input ui-translate-y-label';
      const state = {
        DEFAULT: () => ({
          border: 'c-border-gray-200 ui-shadow-input-default',
          label: `c-text-gray ui-text-input ${ labelSize }`,
          color: 'var(--c-color-gray-300)',
          infoColor: 'var(--c-color-gray-300)',
        }),
        FOCUS: () => ({
          border: 'c-border-primary-400 ui-shadow-input-focus',
          label: `c-text-primary-400 ui-text-input ${ labelSize }`,
          color: 'var(--c-color-primary-400)',
          infoColor: 'var(--c-color-gray-300)',
        }),
        SUCCESS: () => ({
          border: 'c-border-green-400 ui-shadow-input-success',
          label: `c-text-green-400 ui-text-input ${ labelSize }`,
          color: 'var(--c-color-green-400)',
          infoColor: 'var(--c-color-gray-300)',
        }),
        ERROR: () => ({
          border: 'c-border-red-400 ui-shadow-input-error',
          label: `c-text-red-400 ui-text-input ${ labelSize }`,
          color: 'var(--c-color-red-400)',
          infoColor: 'var(--c-color-red-400)',
        }),
        DISABLED: () => ({
          border: 'c-border-gray-200 ui-shadow-input-default ui-opacity-32',
          label: `c-text-gray ui-text-input ${ labelSize }`,
          color: 'var(--c-color-gray-300)',
          infoColor: 'var(--c-color-gray-300)',
        }),
      };

      return state[this.currentState] ? state[this.currentState]() : state.DEFAULT();
    },
    successIcon() {
      return !this.$scopedSlots.icon && (this.success || this.currentState === 'SUCCESS');
    },
    errorIcon() {
      return !this.$scopedSlots.icon && (this.error || this.currentState === 'ERROR');
    },
    maskConfig() {
      if (!this.mask) {
        return ACCEPT_ALL_REGEX;
      }
      if (Array.isArray(this.mask)) {
        return this.mask.map(mask => ({ mask }));
      }
      return this.mask;
    },
    isDate() {
      return this.type === 'date';
    },
  },
  created() {
    if (this.success) this.currentState = 'SUCCESS';
    if (this.error) this.currentState = 'ERROR';
    if (this.disabled) this.currentState = 'DISABLED';
    if (this.state) this.currentState = this.state;
  },
  watch: {
    success() {
      this.currentState = this.success ? 'SUCCESS' : 'DEFAULT';
    },
    error() {
      if (this.error) {
        this.currentState = 'ERROR';
      }
    },
    disabled() {
      this.currentState = this.disabled ? 'DISABLED' : this.state;
    },
    state(value) {
      this.currentState = value;
    },
  },
  methods: {
    handleFocus() {
      if (this.disabled) return;
      this.isAutofilled = false;
      this.isFocused = true;
      this.currentState = 'FOCUS';
    },
    handleBlur() {
      this.isFocused = false;
    },
    // usado para a label não aparecer no fundo dos valores do autofill do chrome
    // https://medium.com/@brunn/detecting-autofilled-fields-in-javascript-aed598d25da7
    handleAnimation(e) {
      const autofillEvents = {
        autofillstart: true,
        autofillcancel: false,
      };
      if (e.animationName in autofillEvents) {
        this.isAutofilled = autofillEvents[e.animationName];
        this.$emit(e.animationName);
      }
    },
    focus() {
      if (this.disabled || this.isDate) return;
      this.$refs.input.$el.focus();
      this.$emit('focus');
    },
  },
};
</script>

<style scoped>
input::placeholder {
  color: transparent;
}

.disabled,
.disabled input {
  cursor: not-allowed !important;
}
</style>
