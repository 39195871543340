<template>
  <flat-pickr
    :value="value"
    :config="datepickerConfig"
    :name="name"
    ref="datepicker"
    v-on="$listeners"
  />
</template>

<script>
import FlatPickr from 'vue-flatpickr-component';
import { IMask } from 'vue-imask';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

export default {
  name: 'CDatepicker',
  components: {
    FlatPickr,
  },
  props: {
    value: { type: String, default: '' },
    name: { type: String, default: '' },
  },
  mounted() {
    // Existem dois inputs onde deve pegar o elemento que não é o hidden
    const inputElement = this.$refs.datepicker.$el.parentNode.querySelector('input:not([type="hidden"])');

    // Faz a máscara para o date quando é permitido digitar no input de date
    new IMask(inputElement, {
      mask: '00/00/0000',
    });
  },
  computed: {
    datepickerConfig() {
      return {
        allowInput: true,
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Portuguese,
        disableMobile: true, // Faz com que seja ignorado o datepicker nativo do mobile (https://flatpickr.js.org/mobile-support/)
        altInputClass: 'ui-w-full focus:ui-outline-none ui-text-input-value ui-font-medium ui-text-input ui-transform ui-translate-y-input ui-block c-bg-transparent',
      };
    },
  },
};
</script>
