<template>
  <div>
    <c-input
      v-bind="$props"
      @blur="handleBlur"
      @focus="handleFocus"
      v-on="$listeners"
      :info="info"
      :state="state"
      :mask="mask"
      :masked="masked"
      :data-cy="dataCy"
      :class="{ 'ui-opacity-60': disabled }"
    >
      <template
        v-if="currentIcon"
        slot="icon"
        slot-scope="iconScope"
      >
        <div @click.stop="$emit('handle-icon-click')">
          <Icon
            :name="currentIcon"
            class="ui-transition-all ui-in-out ui-duration-300 ui-z-50"
            :color="iconScope.icon.iconStyle"
            :filled="iconFilled"
          />
        </div>
      </template>
    </c-input>
  </div>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';
import CInput from '@/components/c-input';

export default {
  inheritAttrs: false,
  name: 'PInput',
  components: {
    CInput,
    Icon,
  },
  data() {
    return {
      state: 'DEFAULT',
      iconError: 'error',
      iconSuccess: 'check',
      iconFilled: false,
      isFocused: false,
    };
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    mask: {
      type: [String, Array, RegExp],
      default: null,
    },
    masked: {
      type: Boolean,
      default: false,
    },
    iconInfo: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: 255,
    },
  },
  computed: {
    info() {
      const info = {
        message: this.hint,
        icon: this.iconInfo,
      };

      switch (this.state) {
      case 'FOCUS':
        info.message = this.hint;
        info.icon = this.iconInfo;
        break;
      case 'ERROR':
        info.message = this.errorMessage;
        info.icon = null;
        break;
      case 'SUCCESS':
        info.message = this.hint;
        info.icon = this.iconInfo;
        break;
      }

      return info;
    },
    currentIcon() {
      const isPassword = this.type === 'password';
      if (!this.state || this.icon) return this.icon;
      switch (true) {
      case this.state === 'ERROR' && !isPassword :
        return this.iconError;
      case this.state === 'SUCCESS' && !isPassword :
        return this.iconSuccess;
      case this.state === 'FOCUS' && !isPassword :
        return this.icon;
      default:
        return this.icon;
      }
    },
  },
  methods: {
    handleFocus() {
      this.state = 'FOCUS';
      this.isFocused = true;
    },
    handleBlur() {
      this.isFocused = false;
      this.validate();
    },
    setErrorState() {
      this.state = 'ERROR';
      if (!this.icon) this.iconFilled = true;
    },
    setSuccessState() {
      this.state = 'SUCCESS';
      if (!this.icon) this.iconFilled = true;
    },
    validate({ dryRun = false } = {}) {
      if (this.errorMessage) {
        if (!dryRun) this.setErrorState(this.errorMessage);
        return false;
      } else {
        if (!dryRun) this.setSuccessState();
        return true;
      }
    },
  },
  watch: {
    errorMessage() {
      if (!this.isFocused) {
        this.validate();
      }
    },
  },
};
</script>
